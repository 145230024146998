import gql from 'graphql-tag'


const response = `
  vendorId productId uid
`

export const DETAIL_STATUS = (templateType) => gql`query DETAIL_STATUS ($deviceType: String!, $deviceId: Int!) {
  status: detail${templateType}Status (deviceType: $deviceType, deviceId: $deviceId) {${response}}
}`

export const CONFIG_DEVICE = (templateType) => gql`mutation CONFIG_DEVICE ($deviceType: String!, $deviceId: Int!, $input: ${templateType}ConfigInput!) {
  config: config${templateType} (deviceType: $deviceType, deviceId: $deviceId, input: $input) {${response}}
}`


export const SETUP_DEVICE_UID = (templateType) => gql`mutation SETUP_DEVICE_UID ($deviceType: String!, $deviceId: Int!) {
  uid: setup${templateType}Uid (deviceType: $deviceType, deviceId: $deviceId)
}`

export const DEVICE_SCANNED = (templateType) => gql`subscription DEVICE_SCANNED ($uid: String!) {
  deviceScanned: watch${templateType}Scanned (uid: $uid) {
    uid barcode
  }
}`
